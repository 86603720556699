@keyframes drop {
    0% {
        transform: translateY(-20px) scale(.9);
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

@keyframes ldio-r68llg26yv {
    0% {
        transform: rotate(0deg)
    }

    50% {
        transform: rotate(22.5deg)
    }

    100% {
        transform: rotate(45deg)
    }
}

@keyframes enter {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes flip {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: translateY(10px) rotate(20deg);
        opacity: 1;
    }

    75% {
        transform: rotate(110deg);
    }

    80% {
        transform: rotate(125deg);
    }

    90% {
        transform: rotate(110deg);
    }

    100% {
        transform: rotate(100deg);
        opacity: 0;
        filter: grayscale(100);
    }
}

@keyframes bounce {
    50% {
        transform: rotate(10deg);
    }
}

@keyframes movingGhost {
    0% {
        transform: translateX(350%);
    }

    49% {
        transform: translateX(-1500%) scaleX(1);
    }

    50% {
        transform: translateX(-1500%) scaleX(-1);
    }

    51% {
        transform: translateX(-1500%) scaleX(-1);
    }

    100% {
        transform: translateX(500%) scaleX(-1);
    }
}

@keyframes levitate {
    50% {
        transform: translateY(-15px);
    }
}

@keyframes levitate-small {
    50% {
        transform: translateY(-5px);
    }
}


@keyframes pop {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(-2.5px) rotate(-2deg);
    }

    50% {
        transform: translateX(2.5px) rotate(2deg);
    }

    75% {
        transform: translateX(-2.5px) rotate(-2deg);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes typing {
    0%{
        width: 0;
    }
    20%{
        width: 0;
    }
    
    40%{
        width: 11ch;
    }
    70%{
        width: 11ch;
    }
    100%{
        width: 0;
    }
}

@keyframes blinking {
    0%{
        border-right: 1px solid transparent;
    }
    100%{
        border-right: 1px solid var(--dark-blue-black);
    }
}

@keyframes dramaticEntrance {
    0%{
        opacity: 0;
        transform: translateY(3rem) scale(.8);
    }
    70%{
        opacity: 1;
        transform: translateY(-1rem) scale(1.5);
    }
    80%{
        opacity: 1;
        transform: translateY(-1rem) scale(2);
    }
    90%{
        opacity: 1;
        transform: translateY(-1rem) scale(.5);
    }
    100%{
        opacity: 1;
        transform: translateY(-1rem) scale(1);
    }
}

@keyframes dropMobile {
    from{
        transform: translateY(5rem);
    }
    to{
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes opac {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes drop-nav {
    from{
        top: -100%;
        opacity: 0;
    }
    to{
        top: 0;
        opacity: 1;
        backdrop-filter: blur(10px);
        background-color: rgba(255, 255, 255, 0.15);
    }
}

@keyframes move-a-little {
    0%{
        transform: translateX(0) scale(1);
    }
    50%{
        transform: translateX(100px) scale(1.15);
    }
    100%{
        transform: translateX(0px) scale(1);
    }
}

@keyframes img-bouncing {
    50%{
        transform: translateY(-25px);
        opacity: 1;
    }
}

@keyframes rgbSpin {
    50%{
        filter: grayscale(1);
        opacity: .5;
        transform: scale(.9);
    }
}

@keyframes beatingHeartErr {
    50%{
        border: 1px solid rgba(238, 50, 97, .25);
        box-shadow: 0 0 25px rgba(238, 50, 97, .25);
    }
}